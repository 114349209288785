import classNames from 'classnames';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import { Column, Container, Row } from '../Grid';
import Icon from '../Icon';
import Image from '../Image';
import Video from '../Video';
import './styles.scss';

const EpisodePlayer = ({
  about,
  episodes,
  className,
  description,
  guideImg,
  guideTitle,
  guideSubtitle,
  guideDescription,
  guideAudience,
  guideDownloadUrl,
  guideSize,
  guideDuration,
  guideSubjectArea,
  data
}) => {
  const [activeIndex, setActiveIndex] = useState(0); // zero-based index
  const viewerRef = useRef(null);
  const { hash: currentHash } = useLocation();

  useEffect(() => {
    if(currentHash) {
      let episodeIndex = episodes.findIndex(i => i.id === currentHash.replace('#', ''));
      if (episodeIndex !== -1) {
        setActiveIndex(episodeIndex);
      }
    }
  })

  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          id={episode.id || episode.title
            .replace(/[^a-zA-Z ]/g, '')
            .replace(/ /g, '-')
            .toLowerCase()}
          className="episode"
          onClick={() => {
            setActiveIndex(index);
            // if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
            //   viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            // }
          }}
        >
          <div
            className={classNames(
              'episode__image-container',
              index === activeIndex && 'episode__image-container--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div className="episode__text-container">
            <h5 className="episode__title">{episode.title}</h5>
            <p className="episode__duration-description">
              {episode.duration}
              <br />
              {episode.description}
            </p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={episode.dexSrc}
        poster={episode.posterSrc}
        className="video-player"
        title={episode.title}
      />
    );
  };

  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <>
        <Container className="episode-description" fullWidth>
          <h1 className="episode-description__title">{episode.title}</h1>
          <h2 className="episode-description__subtitle">{episode.subtitle}</h2>
          <h2 className="episode-description__instructor-name">
            {episode.instructor.name}—{episode.instructor.role},{' '}
            {episode.instructor.school}
          </h2>
          <div className="episode-description__important-themes-header-container">
            <span style={{ fontWeight: 700 }}>Important</span>{' '}
            <span style={{ fontWeight: 600 }}>Themes</span>
          </div>

          {episode.importantThemes && (
            <div className="episode-description__important-themes-container">
              {episode.importantThemes.map((importantTheme, i) => (
                <div
                  className="episode-description__important-theme-container"
                  key={i}
                >
                  <Icon
                    name="circlecheck"
                    className="resource__action-icon-download"
                    marginLeft
                  />
                  <p>{importantTheme}</p>
                </div>
              ))}
            </div>
          )}

          <div className="episode-description__educator-header-container">
            <p>
              <b>Educator Background</b>
            </p>
          </div>

          <div className="episode-description__educator-container">
            <Image
              filename={episode.instructor.image}
              className="episode-description__educator-image"
            />
            <div className="episode-description__educator-info-container">
              <h1 className="episode-description__educator-info-name">
                {episode.instructor.name}
              </h1>
              <p className="episode-description__educator-info-school">
                {episode.instructor.role}, {episode.instructor.school}
              </p>
              <p className="episode-description__educator-info-background">
                {episode.instructor.background}
              </p>
            </div>
          </div>
        </Container>
      </>
    );
  };

  const renderAboutGuide = () => {
    return (
      <div className="master-about">
        <h1 className="master-about__title">About this master class series</h1>
        <p className="master-about__description">{about}</p>
      </div>
    );
  };

  const renderEducatorGuide = () => {
    return (
      <div className="master-educator-guide">
        <div className="master-educator-guide__tophat">Educator guide</div>
        <Image
          className="master-educator-guide__guide-image"
          filename="master-educator-guide-thumb.png"
        />
        <div className="master-educator-guide__content-container">
          <div className="master-educator-guide__subject-area">
            {guideSubjectArea}
          </div>
          <div className="master-educator-guide__title">{guideTitle}</div>
          <div className="master-educator-guide__subtitle">{guideSubtitle}</div>
          <div className="master-educator-guide__audience">{guideAudience}</div>
          <div className="resource__duration">
            <Icon name="clock" marginRight /> {guideDuration}
          </div>
          <div className="master-educator-guide__description">
            {guideDescription}
          </div>
          <div className="master-educator-guide__button-container">
            <Button target="_blank" to={guideDownloadUrl}>
              <Icon name="download" marginRight />
              <span className="master-educator-guide__button-text">
                Educator guide
              </span>
            </Button>
            <p className="master-educator-guide__guide-size">
              .PDF {guideSize}
            </p>
          </div>
          {about && renderAboutGuide()}
        </div>
      </div>
    );
  };

  return (
    <>
      <Container fullWidth className={`episode-player ${className}`}>
        <div className="episode-player__video-player">
          {renderCurrentVideo()}
        </div>
        <div className="episode-player__episodes">{renderEpisodes()}</div>
        <div className="episode-player__description">{renderCurrentData()}</div>
        <div className="episode-player__educator-guide">
          {renderEducatorGuide()}
        </div>
      </Container>
    </>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  guideImg: PropTypes.string,
  guideTitle: PropTypes.string,
  guideDescription: PropTypes.string,
  guideAudience: PropTypes.string,
  guideDownloadUrl: PropTypes.string,
  guideSize: PropTypes.string,
  about: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
  guideImg: '',
  guideSize: '',
};

export default EpisodePlayer;
